import { getCurrentLocalization, getLocalizationFromAvailable } from 'helpers/localization';

const getLocalization = () => {
    const localeFromStorage = localStorage.getItem('language');
    const navigatorLanguage = getLocalizationFromAvailable();
    const languageFromURL = getCurrentLocalization();

    return navigatorLanguage || languageFromURL || localeFromStorage;
};

export default getLocalization;
