import getLocalization from 'services/i18n/getLocalization';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import i18next from 'i18next';

import { DEFAULT_LANGUAGE } from 'constants/localization';

const lng = getLocalization() || undefined;

i18next
    .use(backend)
    .use(ICU)
    .use(initReactI18next)
    .init({
        lng,
        fallbackLng: [DEFAULT_LANGUAGE],
        ns: ['translation'],
        preload: [DEFAULT_LANGUAGE],
        debug: false,
        react: {
            useSuspense: false,
        },
        nsSeparator: false,
        keySeparator: false,
    });
